import React from 'react'

import Publications from '../assets/publications.json'
// import { ReactComponent as Cross } from '../assets/others/cross-thin.svg'

import '../css/Filters.scss'

export const Filters = ({ filters, toggleFilter }) => {
  const categoryElements = {}

  for (let division in Publications.divisionsOfCategories) {
    const categoriesInDivision = Publications.divisionsOfCategories[division]
    categoryElements[division] = []

    for (let categoryAbbr of categoriesInDivision) {
      const categorySelected = filters.includes(categoryAbbr)

      categoryElements[division].push(
        <p
          className={`filter-item${categorySelected ? ' selected-filter' : ''}`}
          key={categoryAbbr}
          data-category={categoryAbbr}
          onClick={toggleFilter}
        >
          {Publications.categories[categoryAbbr]}
          {/* <span className="selected-filter-cross">
            <Cross />
          </span> */}
        </p>
      )
    }
  }

  return (
    <div className="project-filters">
      <div className="division">
        {/* <h3>Interaction Modalities</h3> */}
        <div className="filters">
          {categoryElements['Interaction Modalities']}
        </div>
      </div>

      {/* <div className="division">
        <h3>Application Areas</h3>
        <div className="filters">{categoryElements['Application Areas']}</div>
      </div> */}
    </div>
  )
}
