import Publications from '../assets/publications.json'

export const parseProjectAward = award => {
  if (!award.length) return false
  if (award === 'HM') return '🏅 Honorable Mention'
  else if (award === 'BEST_DESIGN') return '🏆 Best Design Paper'
  else if (award === 'BEST') return '🏆 Best Paper'
}

export const parseProjectPaper = paper => {
  if (!paper.length) return ''
  if (paper.includes('http') && paper.includes('//')) {
    // external link
    return paper
  } else {
    // internal link
    return `./papers/${paper}`
  }
}

export const parseProjectWebsite = website => {
  if (!website.length) return ''
  if (website.includes('http') && website.includes('//')) {
    // external link
    return website
  } else {
    // internal link
    // return `./projects/${website}`
    return `./${website}`
  }
}

export const sortPeopleList = (people, ordering, status) => {
  if (status === 'current') {
    const groupedPeople = []
    for (let type of ordering) {
      const filteredPeople = people.filter(person => person.category === type)
      groupedPeople.push(
        ...filteredPeople.sort((a, b) =>
          getLastName(a.name).localeCompare(getLastName(b.name))
        )
      )
    }
    return groupedPeople
  } else {
    return people
      .sort((a, b) => getLastName(a.name).localeCompare(getLastName(b.name)))
      .sort((a, b) => b.time[1] - a.time[1])
  }
}

const getLastName = name => {
  const nameList = name.split(' ')
  return nameList[nameList.length - 1]
}

// https://codepen.io/eclarrrk/pen/ZZywZv
export function copyToClipboard(text, message) {
  var dummy = document.createElement('input')
  document.body.appendChild(dummy)
  dummy.setAttribute('value', text)
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)

  message.innerHTML = 'copied to clipboard'
  setTimeout(() => {
    message.innerHTML = 'click to copy'
  }, 1000)
}

/* -------------------------------------------------------------------------- */

// project and people

export const findProject = shortcut => {
  const project = Publications.papers.find(
    project => project.shortcut === shortcut
  )

  return project
}

export const projectHasCategory = (projectCategories, categories) => {
  for (let category of categories) {
    if (projectCategories.includes(category)) return true
  }
  return false
}

export const getAlumniTime = time => {
  if (time[0] === time[1]) return ` ${time[0]}`
  else return ` ${time[0]} - ${time[1]}`
}
