import React, { Component } from 'react'

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import Footer from '../components/Footer'
import Header from '../components/Header'
// import { ListLinks } from '../components/ListLink'
import {
  findProject,
  // parseProjectPaper,
  // parseProjectWebsite,
} from '../components/utils'
import { PageNotFound } from './404'

import '../css/pages.scss'

export default class Projects extends Component {
  constructor(props) {
    super(props)

    this.state = {
      html: '',
      notAProject: false,
    }

    this.projectOrBioShortcut = null
    this.project = null
  }

  getThisProjectPage() {
    const path = window.location.pathname.split('/')
    return (this.path = path[path.length - 1])
  }

  getProjectOrPerson() {
    this.projectOrBioShortcut = this.getThisProjectPage()
    this.project = findProject(this.projectOrBioShortcut)
  }

  componentDidMount() {
    this.getProjectOrPerson()

    if (this.project) {
      if (this.project) {
        document.title = `${this.project.name} | Kexin 'Bella' Yang`
        fetch(require(`../assets/pages/${this.projectOrBioShortcut}.md`))
          .then(response => {
            return response.text()
          })
          .then(text => {
            this.setState({
              html: text,
            })
          })
      }
    } else if (!this.state.notAProject) {
      document.title = `404 | Kexin 'Bella' Yang`
      this.setState({
        notAProject: true,
      })
    }
  }

  // ? why do we need it here
  componentDidUpdate() {
    this.getProjectOrPerson()

    if (this.project) {
      if (this.project)
        document.title = `${this.project.name} | Kexin 'Bella' Yang`
    } else if (!this.state.notAProject) {
      document.title = `404 | Kexin 'Bella' Yang`
      this.setState({
        notAProject: true,
      })
    }
  }

  render() {
    return this.state.notAProject ? (
      <>
        <Header highlighted="" />
        <div className="page-view project-page-view">
          <PageNotFound />
        </div>
      </>
    ) : (
      <>
        <Header highlighted="" />

        <div className="page-view project-page-view">
          {/* -------------------------------------------------------------------------- */}
          {this.project && (
            <div className="project-meta">
              <h1>
                <b>{this.project.name}</b> <span>{this.project.title}</span>
              </h1>
              <p className="project-authors">{this.project.authors}</p>
              {/* <ListLinks
                name={this.project.name}
                links={[
                  {
                    name: 'doi',
                    value: this.project.doi,
                  },
                  {
                    name: 'paper',
                    value: parseProjectPaper(this.project.paper),
                  },
                ]}
              /> */}
            </div>
          )}
          {/* -------------------------------------------------------------------------- */}
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {this.state.html}
          </ReactMarkdown>
        </div>

        <Footer />
      </>
    )
  }
}
