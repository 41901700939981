import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import '../css/Header.scss'

export default class Header extends Component {
  render() {
    return (
      <header>
        <Link
          key="lab-title"
          id="lab-title-header"
          className="lab-title"
          to="/"
        >
          Kexin 'Bella' Yang
        </Link>
        <HeaderMenu highlighted={this.props.highlighted} />
      </header>
    )
  }
}

const HeaderMenu = ({ highlighted }) => {
  const highlightedClass = thisName =>
    `header-link ${thisName}${highlighted === thisName ? ' highlighted' : ''}`

  return (
    <ul id="header-menu">
      <li>
        <Link to="/" className={highlightedClass('research')}>
          <span>Research</span>
        </Link>
      </li>
      <li>
        <Link
          to="https://docs.google.com/document/d/1c63ywFDCBsRtFupHawaesbrveUgktvGqi9-dK6kq9NI/edit?usp=sharing"
          className={highlightedClass('cv')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>CV</span>
        </Link>
      </li>
      <li>
        <Link
          to="https://kxyang.com/portfolio/"
          className={highlightedClass('side')}
        >
          <span>Side Projects</span>
        </Link>
      </li>
      <li>
        <Link to="/contact" className={highlightedClass('contact')}>
          <span>Contact</span>
        </Link>
      </li>
      {/* <li>
        <Link to="/join" className={highlightedClass('join')}>
          <span>join us</span>
        </Link>
      </li> */}
    </ul>
  )
}
