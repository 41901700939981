import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './css/index.scss'

import App from './App'
import Contact from './pages/Contact'
import ScrollToTop from './components/ScrollToTop'
import Projects from './pages/Projects'
// import Team from './pages/Team'
// import Join from './pages/Join'
// import Collection from './pages/Collection'

// import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<App />} />
        {/* <Route exact path="/team" element={<Team />} /> */}
        <Route exact path="/contact" element={<Contact />} />
        {/* <Route exact path="/join" element={<Join />} /> */}
        {/* -------------------------------------------------------------------------- */}
        <Route path="/*" element={<Projects />} />
        {/* <Route path="/projects/*" element={<Projects />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)
