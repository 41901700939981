import React, { Component, useRef } from 'react'

import Publications from '../assets/publications.json'

import {
  parseProjectAward,
  parseProjectPaper,
  projectHasCategory,
} from './utils'

import '../css/List.scss'

export class ProjectList extends Component {
  render() {
    const { filters } = this.props

    const projects = []
    for (const projectInd in Publications.papers) {
      const project = Publications.papers[projectInd]

      projects.push(
        <Project
          key={project.shortcut}
          show={
            filters.length === 0 ||
            projectHasCategory(project.categories, filters)
          }
          order={projectInd}
          year={project.year}
          name={project.name}
          title={project.title}
          thumbnail={project.thumbnail}
          animatedThumbnail={project.animated_thumbnail}
          authors={project.authors.split(', ')}
          award={project.award}
          doi={project.doi}
          paper={project.paper}
          video={project.video}
          preview={project.preview}
          website={project.website}
          bibTex={project.bibTex}
          extra={project.extra}
        />
      )
    }

    return <ul className={`list-view project-list`}>{projects}</ul>
  }
}

const Project = ({
  show,
  order,
  year,
  name,
  title,
  thumbnail,
  animatedThumbnail,
  authors,
  award,
  doi,
  paper,
  video,
  preview,
  website,
  bibTex,
  extra,
}) => {
  let imageSource = require(`../assets/thumbnails/${thumbnail}`)
  let animatedImageSource = animatedThumbnail
    ? require(`../assets/thumbnails/${animatedThumbnail}`)
    : null

  // authors
  // const authorList = authors.map(author => (
  //   <span key={author} className="project-author">
  //     {author}
  //   </span>
  // ))
  const authorList = (
    <span className="project-author">{authors.join(', ')}</span>
  )

  const listItemEl = useRef(null)
  // useEffect(() => {
  //   const listItem = listItemEl.current

  //   if (listItem.clientHeight > 0)
  //     listItem.style.height = `${listItem.scrollHeight}px`
  //   else listItem.style.height = 'unset'
  // })

  const newProject = <span className="new-project-label">coming soon</span>

  return (
    <li
      ref={listItemEl}
      className={`list-item project-item${show ? '' : ' list-item-hidden'}${
        animatedImageSource ? ' project-item-has-animated' : ''
      }`}
      style={{
        zIndex: order,
      }}
    >
      <img src={imageSource} alt={name} className="list-image project-image" />
      {animatedImageSource && (
        <img
          src={animatedImageSource}
          alt={name}
          className="list-image project-image-animated"
        />
      )}

      <div
        className={`list-content project-content${
          show ? '' : ' list-content-hidden'
        }`}
      >
        <div className="project-info">
          <p className="project-year">
            <span>{year.replace('NEW_', '')}</span>
            {year.includes('NEW_') && newProject}
          </p>
          {parseProjectAward(award) && (
            <p className="project-award">{parseProjectAward(award)}</p>
          )}
        </div>

        <div className="list-content-center">
          <a href={parseProjectPaper(paper)}>
            <p className="list-item-title">
              <span className="item-name">{name}</span>{' '}
              <span className="item-title">{title}</span>
            </p>
          </a>
          {authorList}
        </div>

        {/* <ListLinks
          name={name}
          links={[
            {
              name: 'doi',
              value: doi,
            },
            {
              name: 'paper',
              value: parseProjectPaper(paper),
            },
            {
              name: 'video',
              value: video,
            },
            {
              name: 'preview',
              value: preview,
            },
            {
              name: 'website',
              value: parseProjectWebsite(website),
            },
            {
              name: 'bibtex',
              value: bibTex,
            },
            ...(extra ?? []),
          ]}
        /> */}
      </div>
    </li>
  )
}
