import React, { Component } from 'react'

import '../css/Intro.scss'

// import image
import KexinHead from '../assets/images/kexin-head.jpg'

export default class Intro extends Component {
  render() {
    const { introContent } = this.props
    return (
      <div className="research-intro">
        <img
          alt="Kexin Yang"
          src={KexinHead}
          style={{
            width: '20rem',
            height: '20rem',
            paddingBottom: '1rem',
          }}
        ></img>
        {introContent}
        {/* <br /> */}
      </div>
    )
  }
}
