import { Suspense, useRef, useState } from 'react'
import { Filters } from './components/Filters'
import Footer from './components/Footer'
import Header from './components/Header'
import Intro from './components/Intro'
import { ProjectList } from './components/List'

function App() {
  const [filters, setFilters] = useState([])
  const publicationRef = useRef(null)

  const toggleFilter = e => {
    let ele = e.target
    while (!ele.dataset?.category) {
      ele = ele.parentNode
      if (!ele) return
    }

    const {
      dataset: { category },
    } = ele

    // const newFilters = [...filters]
    // if (newFilters.includes(category)) {
    //   return setFilters(newFilters.filter(f => f !== category))
    // } else {
    //   return setFilters([...newFilters, category])
    // }
    document.querySelectorAll('.project-item').forEach(projectItemEl => {
      projectItemEl.style.height = `${projectItemEl.scrollHeight}px`
    })

    setTimeout(() => {
      document.querySelectorAll('.project-item').forEach(projectItemEl => {
        projectItemEl.style.height = `unset`
      })
    }, 301)

    scrollToPublication()

    const newFilters = [...filters]
    if (newFilters.includes(category)) {
      return setFilters([])
    } else {
      return setFilters([category])
    }
  }

  const clearFilter = e => {
    scrollToPublication()
    if (filters.length > 0)
      toggleFilter({ target: { dataset: { category: filters[0] } } })
  }

  const scrollToPublication = e => {
    if (window.pageYOffset < publicationRef.current?.offsetTop)
      window.scrollTo({
        top: publicationRef.current?.offsetTop,
        behavior: 'smooth',
      })
  }

  return (
    <>
      <Header highlighted="research" />
      <div className="list-container list-container-projects">
        <Intro
          introContent={
            <p>
              My research aims to leverage multi-modal analytics and design
              data-driven human-AI algorithmic systems for smart classrooms of
              the future, that
              <ol className="intro-list">
                <li>
                  respect stakeholders’ (teachers and students) boundaries,
                  agency, and preferences,
                </li>

                <li>
                  augment educators' abilities to distribute their limited
                  attention to where it is needed the most,
                </li>
                <li>
                  achieve effective, self-paced personalized learning that suit
                  students’ individual needs.
                </li>
              </ol>
              I’ve also worked on and broadly interested in social computing
              (e.g., crowdsourcing), XR, robotics, NLP, and their application in
              education. I am well versed in qualitative, quantitative, and
              human-centered design research methods, including surveys,
              interviews, prototyping, focus groups, participatory design,
              usability testing, think-aloud protocol, field testing, AB
              testing, log-data analysis, statistical modeling and experiment
              design. I published first-authored papers in HCI and education
              venues including CHI, CSCW, AIED, EDM and EC-TEL.
            </p>
          }
        />
        <Suspense>
          <h2
            ref={publicationRef}
            className="list-h2"
            onClick={clearFilter}
            style={{
              cursor: 'pointer',
            }}
          >
            Publications
          </h2>
          <Filters filters={filters} toggleFilter={toggleFilter} />
          <ProjectList filters={filters} />
        </Suspense>
      </div>

      <Footer />
    </>
  )
}

export default App
