import React, { useEffect, useRef } from 'react'

import Footer from '../components/Footer'
import Header from '../components/Header'
import { useScript } from '../components/useScript'
import { copyToClipboard } from '../components/utils'

import '../css/Contact.scss'

const Contact = () => {
  const mapContainer = useRef(null)
  const emailMessage = useRef(null)

  const status = useScript(
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyB9ezLgUJcokXNQkg9Ey41styJ_rz5ftyw'
  )
  useEffect(() => {
    if (status === 'ready') {
      initMap(mapContainer.current)
    }
  })

  return (
    <>
      <Header highlighted="contact" />
      <div ref={mapContainer} className="map-container"></div>
      <div className="contact-info">
        <ul>
          <li>
            Newell-Simon Hall, Human-Computer Interaction Institute,
            <br />
            Carnegie Mellon University (
            <a
              href="https://goo.gl/maps/GrabJoYPpK7hpRRU7"
              target="_blank"
              rel="noopenner noreferrer"
            >
              map
            </a>
            )
          </li>
          <li>
            <p
              onClick={() => {
                copyToClipboard('kexiny@cs.cmu.edu', emailMessage.current)
              }}
            >
              <span ref={emailMessage} className="copy-message">
                click to copy
              </span>
              kexiny@cs.cmu.edu
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  )
}

export default Contact

function initMap(component) {
  const designLab = { lat: 40.4432519, lng: -79.9464131 }
  const map = new window.google.maps.Map(component, {
    zoom: 17,
    center: designLab,
    disableDefaultUI: true,
    styles: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#bdbdbd',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
  })
  new window.google.maps.Marker({
    position: designLab,
    map: map,
  })
}
